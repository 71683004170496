import React from "react"

import "./Document.css"

type DocumentSection = {
  slug: string
  title: string
  content: string
}

interface DocumentProps {
  title: string
  version: string
  lastModified: string
  introduction: string
  sections: DocumentSection[]
}

export const Document = ({
  title,
  version,
  lastModified,
  introduction,
  sections,
}: DocumentProps) => {
  return (
    <div className="Document">
      <div className="wrapper">
        <h1>{title}</h1>
        <small>
          Version {version} <br />
          Last modified {lastModified}
        </small>
        <div
          className="Introduction"
          dangerouslySetInnerHTML={{ __html: introduction }}
        />
        <div className="TableOfContents">
          <h2>Table of contents</h2>
          <ul>
            {sections.map(s => (
              <li key={s.slug}>
                <a href={`#${s.slug}`}>{s.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          {sections.map(s => (
            <div key={s.slug} id={s.slug}>
              <h2>{s.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: s.content }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
