import React, { ReactChild } from "react"
import clsx from "clsx"
import "./button.css"

import { LoadingIndicator } from "../LoadingIndicator"

interface ButtonProps {
  children: ReactChild
  className?: string
  disabled?: boolean
  primary?: boolean
  secondary?: boolean
  inverse?: boolean
  submitting?: boolean
  onClick?: () => void
}

export const Button = ({
  children,
  className,
  disabled = false,
  primary = false,
  secondary = false,
  submitting = false,
  inverse = false,
  ...props
}: ButtonProps) => {
  const classNames = clsx("Button", className, {
    disabled,
    primary,
    secondary,
    inverse,
  })
  let onClick
  if (props.onClick) {
    onClick = () => {
      if (props.onClick) {
        props.onClick()
      }
    }
  }
  return (
    <button
      className={classNames}
      disabled={disabled}
      {...props}
      onClick={onClick}
    >
      {submitting ? <LoadingIndicator dotSize="small" inverse /> : children}
    </button>
  )
}
