import React from "react"
import { Link } from "gatsby"
import "./Header.css"

import { useAuthState } from "../../features/Auth/state"
import { IconCross, IconChart } from "../../theme/icons"
import { TipstaLogo } from "../../theme/logo"

interface HeaderProps {
  isProfilePage: boolean
  isLoginPage: boolean
}

export const Header = ({ isLoginPage, isProfilePage }: HeaderProps) => {
  const { initialising, isAnonymous } = useAuthState()
  return (
    <header>
      <Link className="HomeLink" to="/">
        <TipstaLogo size="medium" />
      </Link>
      {!initialising && (
        <div className="UserProfileControl">
          {isAnonymous ? (
            <>
              {!isLoginPage ? (
                <Link className="LoginLink" to="/login">
                  Log In / Sign Up
                </Link>
              ) : (
                <Link className="ProfileLinkHome" to="/">
                  <div className="Back">
                    <IconCross width={20} height={20} />
                    <div>Back</div>
                  </div>
                </Link>
              )}
            </>
          ) : (
            <>
              {isProfilePage ? (
                <Link className="ProfileLinkHome" to="/">
                  <div className="Back">
                    <IconCross width={20} height={20} />
                    <div>Back</div>
                  </div>
                </Link>
              ) : (
                <Link className="ProfileLink" to="/profile">
                  <div className="Profile">
                    <IconChart width={20} height={20} />
                    <div>Profile</div>
                  </div>
                </Link>
              )}
            </>
          )}
        </div>
      )}
    </header>
  )
}
