import React, { Fragment } from "react"
import "./LinkActions.css"

interface LinkActionsProps {
  children: JSX.Element | Array<JSX.Element>
}

export const LinkActions = ({ children }: LinkActionsProps) => {
  const childArray = Array.isArray(children) ? children : [children]
  return (
    <div className="LinkActions">
      {childArray.map((child, index) => (
        <Fragment key={index}>
          {child}
          {index + 1 < childArray.length && (
            <span className="LinkActionDivider">|</span>
          )}
        </Fragment>
      ))}
    </div>
  )
}
