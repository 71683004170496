import React, { useCallback } from "react"
import { navigateTo } from "gatsby"
import "./RecipientMismatch.css"

import { Button } from "../../../../components"
import { AnalyticsService, CustomEvent } from "../../../../lib/analytics"
import { useSubscribeState } from "../../state"
import { useAuthState } from "../../../Auth/state"

interface RecipientMismatchProps {
  continueTo: string
  onClose: () => void
}

export const RecipientMismatch = ({
  continueTo,
  onClose,
}: RecipientMismatchProps) => {
  const { emailAddress, onLogout } = useAuthState()
  const {
    intendedRecipientState: { intendedRecipient },
  } = useSubscribeState()

  const onGoToLogin = useCallback(async () => {
    AnalyticsService.logEvent(CustomEvent.IntendedRecipientSignIn)
    if (emailAddress) {
      await onLogout()
    }
    navigateTo(`/login?continueTo=${encodeURIComponent(continueTo)}`)
    onClose()
  }, [emailAddress, continueTo, onClose])

  return (
    <div className="RecipientMismatch">
      <h3>Login on this device</h3>
      <p>
        You have opened a link intended for <strong>{intendedRecipient}</strong>
      </p>
      <p>
        If this is you, please login on this device before making your
        prediction. Otherwise, click Close and continue normally.
      </p>
      <div className="RecipientMismatchActions">
        <Button onClick={onGoToLogin}>Log In</Button>
        <Button secondary onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  )
}
