import React from "react"
import "./SubscribeBenefits.css"

export const SubscribeBenefits = () => {
  return (
    <div className="Benefits">
      <h4>Features</h4>
      <ul>
        <li>Email notifications for new scenarios</li>
        <li>Notifications for whether you were right or wrong</li>
        <li>Access to your performance metrics</li>
      </ul>
    </div>
  )
}
