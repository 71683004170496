import React from "react"
import "./LoadingIndicator.css"
import clsx from "clsx"

interface LoadingIndicatorProps {
  dotSize?: "small" | "default"
  inverse?: boolean
}

const sizes = {
  default: 12,
  small: 6,
}
const gap = {
  default: 12,
  small: 6,
}

export const LoadingIndicator = ({
  dotSize = "default",
  inverse = false,
}: LoadingIndicatorProps) => {
  const classNames = clsx("lds-ellipsis", {
    small: dotSize === "small",
    inverse,
  })
  const dotStyle = {
    height: sizes[dotSize],
    width: sizes[dotSize],
  }
  const containerStyle = {
    height: sizes[dotSize] + 2,
    width: sizes[dotSize] * 3 + gap[dotSize] * 3,
  }
  return (
    <div className={classNames} style={containerStyle}>
      <div style={dotStyle} />
      <div style={dotStyle} />
      <div style={dotStyle} />
      <div style={dotStyle} />
    </div>
  )
}
