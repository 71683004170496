import React from "react"
import Slider, { SliderProps } from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import "./RangeSlider.css"

interface RangeSliderProps extends SliderProps {
  min: number
  max: number
  value: number
  onChange: (value: number) => void
}

export const RangeSlider = ({
  min,
  max,
  value,
  onChange,
  ...props
}: RangeSliderProps) => {
  return (
    <div className="RangeSlider">
      <Slider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  )
}
