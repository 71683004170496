import React from "react"
import "./DividingTitle.css"

interface DividingTitleProps {
  title: string
}

export const DividingTitle = ({ title }: DividingTitleProps) => {
  return (
    <div className="DividingTitle">
      <h3 className="title">{title}</h3>
    </div>
  )
}
