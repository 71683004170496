import React, { useCallback, useRef, useState, useEffect } from "react"
import "./ShareLinks.css"

import { IconCopy, IconTwitter, IconFacebook } from "../../theme/icons"
import {
  getScenarioShareUrlTwitter,
  getScenarioShareUrlFacebook,
} from "../../lib/sharing"
import { Scenario } from "../../types.gen"

interface ShareLinksProps {
  shareType: "scenario"
  scenario?: Scenario
}

// Share link from web -> take user to tipsta rather than App Store

export const ShareLinks = ({ shareType, scenario }: ShareLinksProps) => {
  if (shareType !== "scenario") return null
  if (!scenario) return null

  const scenarioShareUrlTwitter = getScenarioShareUrlTwitter(scenario)
  const scenarioShareUrlFacebook = getScenarioShareUrlFacebook(scenario)

  const [isCopied, setIsCopied] = useState(false)

  const deepLinkTextRef = useRef<Maybe<HTMLTextAreaElement>>(null)
  const onCopyToClipboard = useCallback(() => {
    if (deepLinkTextRef && deepLinkTextRef.current) {
      deepLinkTextRef.current.select()
      document.execCommand("copy")
      setIsCopied(true)
    }
  }, [setIsCopied])

  useEffect(() => {
    if (isCopied) {
      const timeoutId = setTimeout(() => {
        setIsCopied(false)
      }, 4000)
      return () => clearTimeout(timeoutId)
    }
  }, [isCopied, setIsCopied])

  return (
    <div className="ShareLinks">
      <small className="ShareTitle">Share this question:</small>
      <div className="ShareLinksSocial">
        <a
          className="ShareLink ShareLinkTwitter"
          title="Share to Twitter"
          href={scenarioShareUrlTwitter}
          target="_twitter"
        >
          <IconTwitter />
        </a>
        <a
          className="ShareLink ShareLinkFacebook"
          title="Share to Facebook"
          href={scenarioShareUrlFacebook}
          target="_facebook"
        >
          <IconFacebook />
        </a>
      </div>
      <div className="DirectLink" onClick={onCopyToClipboard}>
        <IconCopy height={20} width={20} />
        <textarea
          className="LinkText"
          ref={deepLinkTextRef}
          rows={1}
          value={scenario.deepLink}
          readOnly
        />
        {isCopied && <span className="CopiedPopup">Copied</span>}
      </div>
    </div>
  )
}
