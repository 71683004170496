import React, { ReactElement, useState, useEffect } from "react"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import firebase from "gatsby-plugin-firebase"
import clsx from "clsx"
import "./SignInForm.css"

// import { EmailInput, Button } from "../../../../components"
// import { IconTick } from "../../../../theme/icons"
// import { useSubscribeState } from "../../../Subscribe/state"
// import { usePreviousValue } from "../../../../hooks"
import { useAuthState } from "../../state"
import { AnalyticsService, CustomEvent } from "../../../../lib/analytics"
import { isClient } from "../../../../lib/utils"
import { AuthService } from "../../services"
import { SubscribeBenefits } from "../../../Subscribe/components"
import { LoadingIndicatorPage } from "../../../../components"

interface SignInFormProps {
  continueTo?: Maybe<string>
  onSignInSuccess: () => void
}

export const SignInForm = ({
  continueTo,
  onSignInSuccess,
}: SignInFormProps): ReactElement => {
  const { onRefreshUserData } = useAuthState()
  const [isInitialised, setIsInitialised] = useState(false)

  // const signInReturnUrl =
  //   (isClient() ? window.location.origin : "") +
  //   `/subscribe-confirm${continueTo ? `?continueTo=${continueTo}` : ""}`
  // const signInReturnUrl =
  //   (isClient() ? window.location.origin : "") + (continueTo ? continueTo : "")

  // const [isEmailLogin, setIsEmailLogin] = useState(false)
  // const {
  //   emailSubscribeState: {
  //     emailAddress,
  //     isSendingSubscribeEmail,
  //     hasSentSubscribeEmail,
  //     onChangeEmailAddress,
  //     onSendSubscribeEmail,
  //     onResendSubscribeEmail,
  //   },
  // } = useSubscribeState()

  // console.log({ isSendingSubscribeEmail, hasSentSubscribeEmail })

  // const subscribe = useCallback(() => {
  //   onSendSubscribeEmail(emailAddress, signInReturnUrl)
  // }, [emailAddress, onSendSubscribeEmail])

  // if (isEmailLogin) {
  //   return (
  //     <div style={{ width: 350, margin: "0 auto" }}>
  //       {!hasSentSubscribeEmail ? (
  //         <div>
  //           <EmailInput
  //             value={emailAddress}
  //             onChange={e => onChangeEmailAddress(e.target.value)}
  //           />
  //           <Button submitting={isSendingSubscribeEmail} onClick={subscribe}>
  //             Submit
  //           </Button>
  //         </div>
  //       ) : (
  //         <div>
  //           <IconTick width={48} height={48} fill="green" />
  //           <p>
  //             We've sent you an email, which contains a sign-in link. Click that
  //             link to complete your sign in.
  //           </p>
  //           <Button
  //             submitting={isSendingSubscribeEmail}
  //             onClick={onResendSubscribeEmail}
  //           >
  //             Resend
  //           </Button>
  //         </div>
  //       )}
  //     </div>
  //   )
  // }

  const [uiConfig, setUiConfig] = useState<Maybe<firebaseui.auth.Config>>(null)
  const [isSigningIn, setIsSigningIn] = useState(false)
  useEffect(() => {
    if (isClient()) {
      const isSignInWithEmail = firebase
        .auth()
        .isSignInWithEmailLink(window.location.href)
      setIsSigningIn(isSignInWithEmail)
      if (!uiConfig) {
        setUiConfig({
          credentialHelper: "none",
          popupMode: true,
          signInFlow: "popup",
          autoUpgradeAnonymousUsers: true,
          signInOptions: [
            // {
            //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            //   signInMethod:
            //     firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
            //   // emailLinkSignIn: () => ({
            //   //   url: signInReturnUrl,
            //   //   handleCodeInApp: true,
            //   // }),
            //   requireDisplayName: false,
            // },
            {
              provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
              signInMethod:
                firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
              // emailLinkSignIn: () => ({
              //   url: signInReturnUrl,
              //   handleCodeInApp: true,
              // }),
              requireDisplayName: false,
            },
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          ],
          callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl): boolean => {
              // console.log("*** signInSuccessWithAuthResult")
              // console.log({ authResult, redirectUrl })
              onRefreshUserData()
              setIsSigningIn(false)
              onSignInSuccess()
              AnalyticsService.logEvent(CustomEvent.CompleteSubscription)
              return false
            },
            signInFailure: async ({ code, credential }) => {
              // console.log("*** signInFailure")
              // console.log({ code, credential })
              if (code != "firebaseui/anonymous-upgrade-merge-conflict") {
                return Promise.resolve()
              }
              const currUser = firebase.auth().currentUser
              if (currUser) {
                const anonymousBearerToken = await currUser.getIdToken()
                await AuthService.handleAnonymousUpgrade(
                  anonymousBearerToken,
                  credential
                )
                onSignInSuccess()
                AnalyticsService.logEvent(CustomEvent.CompleteSubscription)
              }
              setIsSigningIn(false)
            },
            uiShown: () => setIsInitialised(true),
          },
          tosUrl: window.location.origin + "/terms-of-service",
          privacyPolicyUrl: window.location.origin + "/privacy-policy",
        })
      }
    }
  }, [uiConfig, setUiConfig, setIsSigningIn])

  const initialisingAuth = !isInitialised || isSigningIn

  return (
    <div className={clsx("SignInForm", { initialisingAuth })}>
      {initialisingAuth && (
        <div className="Initialising">
          <LoadingIndicatorPage />
        </div>
      )}
      {!initialisingAuth && <h2>Sign In to Tipsta</h2>}
      {!initialisingAuth && <SubscribeBenefits />}
      {/* <button onClick={() => setIsEmailLogin(true)}>Sign in with email</button> */}
      {isClient() && uiConfig && (
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      )}
    </div>
  )
}
