import React, { useCallback } from "react"
import { navigate } from "gatsby"
import "./SubscribeCTA.css"

import { TipstaLogo } from "../../../../theme/logo"
import { Button, TextLink } from "../../../../components"
import { AnalyticsService, CustomEvent } from "../../../../lib/analytics"
import { SubscribeBenefits } from "../SubscribeBenefits"
import { SignInForm } from "../../../Auth/components"

interface SubscribeCTAProps {
  continueTo?: Maybe<string>
  onClose: () => void
}

export const SubscribeCTA = ({
  continueTo,
  onClose,
}: SubscribeCTAProps): JSX.Element => {
  const onGoToSignIn = useCallback(() => {
    AnalyticsService.logEvent(CustomEvent.ScenarioPredictionSignIn)
    navigate(
      `/login${
        continueTo ? `?continueTo=${encodeURIComponent(continueTo)}` : ""
      }`
    )
  }, [])
  return (
    <div className="SubscribeCTA">
      <div className="Logo">
        <TipstaLogo size="small" />
      </div>
      <SignInForm continueTo={continueTo} onSignInSuccess={onClose} />
      {/* 
      <h2>Create an account</h2>
      <div className="BenefitsWrapper">
        <SubscribeBenefits />
      </div>
      <div className="SusbcribeActions">
        <Button className="SubscribeAction" onClick={onGoToSignIn}>
          Log In / Sign Up
        </Button>
      </div> */}
      <div className="ModalClose">
        <TextLink onClick={onClose}>Close</TextLink>
      </div>
    </div>
  )
}
