import React, { ReactElement } from "react"
import "./TextLink.css"

interface TextLinkProps {
  onClick: () => void
  children: ReactElement | string
}

export const TextLink = ({ children, onClick }: TextLinkProps): JSX.Element => {
  return (
    <button className="TextLink" onClick={onClick}>
      <span>{children}</span>
    </button>
  )
}
