import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

type LogoSize = "small" | "medium" | "large"
interface TipstaLogoProps {
  size: LogoSize
}

const sizes: { [key in LogoSize]: number } = {
  small: 30,
  medium: 50,
  large: 100,
}

export const TipstaLogo = ({ size }: TipstaLogoProps): Maybe<JSX.Element> => {
  const data = useStaticQuery(graphql`
    query {
      small: file(relativePath: { eq: "tipsta-logo.png" }) {
        childImageSharp {
          fixed(height: 30) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      medium: file(relativePath: { eq: "tipsta-logo.png" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      large: file(relativePath: { eq: "tipsta-logo.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  const image = data[size]
  if (image) {
    return (
      <Img
        fixed={image.childImageSharp.fixed}
        fadeIn={false}
        // imgStyle={{ objectFit: "contain" }}
        style={{ margin: "0 auto", height: sizes[size] }}
      />
    )
  }
  return null
}
