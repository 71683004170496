import React from "react"
import "./Footer.css"
import { Link } from "gatsby"

interface FooterProps {}

export const Footer = ({}: FooterProps) => {
  return (
    <footer>
      <div className="wrapper">
        <div className="FooterLinks">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span>|</span>
          <Link to="/terms-of-service">Terms of Service</Link>
        </div>
        <div>
          <small>
            Do you have a suggestion for a question to pose on Tipsta? If so, we
            welcome your suggestions at{" "}
            <a href="mailto:feedback@tipsta.co.uk">feedback@tipsta.co.uk</a>.
          </small>
        </div>
        <div className="CompanyIdentity">
          <p>
            Copyright © 2020 Tipsta Ltd, All rights reserved. <br />
            Tipsta Ltd is a company registered in England and Wales, with
            company number 12921166.
          </p>
        </div>
      </div>
    </footer>
  )
}
