import React, { useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import "./Layout.css"

import { Modal, LoadingIndicatorPage, Footer, Header } from "../../components"
import { useSubscribeState } from "../../features/Subscribe/state"
import { useScenarioState } from "../../features/Scenario/state"
import { useAuthState } from "../../features/Auth/state"
import { RecipientMismatch } from "../../features/Subscribe/components"
import { isClient } from "../../lib/utils"

const duration = 0.3

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: 0.1,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
}

interface LayoutProps {
  children: JSX.Element | JSX.Element[]
  isProfilePage?: boolean
  isLoginPage?: boolean
}

export const Layout = ({
  children,
  isProfilePage = false,
  isLoginPage = false,
}: LayoutProps) => {
  const { initialising } = useAuthState()
  const {
    intendedRecipientState: { continueTo, isShowLogin, onCloseLogin },
  } = useSubscribeState()
  const {
    scenarioListState: { listFetchCount: scenarioListFetchCount },
    categoryListState: { listFetchCount: categoryListFetchCount },
  } = useScenarioState()
  const isInitialFetch =
    scenarioListFetchCount === 0 || categoryListFetchCount === 0
  const isLoading =
    isClient() && (initialising || isInitialFetch || isShowLogin)

  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          textAlign: "center",
        }}
      >
        <Header isProfilePage={isProfilePage} isLoginPage={isLoginPage} />
        <AnimatePresence exitBeforeEnter>
          <motion.main
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            {isLoading ? <LoadingIndicatorPage /> : children}
          </motion.main>
        </AnimatePresence>
        <Footer />

        <Modal
          isOpen={isShowLogin}
          onRequestClose={onCloseLogin}
          shouldCloseOnOverlayClick={false}
        >
          <RecipientMismatch continueTo={continueTo} onClose={onCloseLogin} />
        </Modal>
      </div>
    </>
  )
}
