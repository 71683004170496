import React from "react"

import { LoadingIndicator } from "../LoadingIndicator"

export const LoadingIndicatorPage = (): JSX.Element => {
  return (
    <div style={{ padding: 50 }}>
      <LoadingIndicator />
    </div>
  )
}
