import queryString from "query-string"
import { TIPSTA_WEBSITE_BASE_URL } from "../config"
import { Scenario } from "types.gen"

export const getReferrerQueryString = (medium: string = "", source: string = "", campaign: string = ""): string => {
  const params = {
    utm_medium: medium,
    utm_source: source,
    utm_campaign: campaign,
  }
  return queryString.stringify(params)
}

export const getScenarioShareUrl = (scenarioId: string, source: string): string => {
  return `${TIPSTA_WEBSITE_BASE_URL}/scenario/${scenarioId}?${getReferrerQueryString("social", source, "share-scenario")}`
}

export const getScenarioShareUrlTwitter = (scenario: Scenario): string => {
  return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    scenario.deepLink
  )}&text=${encodeURIComponent(
    scenario.title
  )}&via=Tipsta_App&hashtags=${encodeURIComponent(scenario.category.title)}`
}

export const getScenarioShareUrlFacebook = (scenario: Scenario): string => {
  return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
    scenario.deepLink
  )}`
}