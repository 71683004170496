import React, { ReactElement, ReactChild } from "react"
import ReactModal from "react-modal"

import "./Modal.css"

interface ModalProps extends ReactModal.Props {
  children: ReactChild
}

ReactModal.setAppElement("#___gatsby")

export const Modal = ({ children, ...props }: ModalProps): ReactElement => {
  return (
    <ReactModal
      {...props}
      closeTimeoutMS={300}
      className={{
        base: "Modal",
        afterOpen: "ModalAfterOpen",
        beforeClose: "ModalBeforeClose",
      }}
      overlayClassName={{
        base: "ModalOverlay",
        afterOpen: "ModalOverlayAfterOpen",
        beforeClose: "ModalOverlayBeforeClose",
      }}
    >
      <div className="ModalBody">{children}</div>
    </ReactModal>
  )
}
